export enum RelaxEnum {
  active = 1,
  Inactive = 0,
}

export enum ScoreStatusEnum {
  Pending = "pending",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
}

export enum SequenceTypeEnum {
  Raw = "raw",
  New = "new",
}

export enum SchemeEnum {
  Kabat = "kabat",
  Imgt = "imgt",
  Chothia = "chothia",
  Aho = "aho",
}

export enum CdrDefEnum {
  Kabat = "kabat",
  Imgt = "imgt",
  Chothia = "chothia",
  North = "north",
}

export enum RecommendModelEnum {
  GeoHumAb = "GeoHumAb",
  ESM23B = "ESM2-3B",
  ESM1V650M = "ESM1V-650M",
  // GermlineSequences = "Germline Sequences",
}

export enum AdvisorEnum {
  AiCopilot = "ai_copilot",
  GermlineSequence = "germline_sequence",
}

export enum ScoreModelEnum {
  GeoHumanness = "GeoHumanness",
  SideChainPKa = "SideChainPKa",
  Charge = "Charge",
  ChargedPatch = "ChargedPatch",
  Hydrophobicity = "Hydrophobicity",
  HydrophobicPatch = "HydrophobicPatch",
}

export enum MutationModelEnum {
  Geohumab = "geohumab",
  CdrGrafting = "cdr_grafting",
}

export enum TreeItemTypeEnum {
  File = "file",
  Job = "job",
  Dataset = "dataset",
}

export enum AlignCmdEnum {
  Align = "align",
  Super = "super",
  Cealign = "cealign",
  Dockq = "dockq",
}

export enum AlignStatusEnum {
  NotCreated = "not_created",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
}

export enum TaskPollingEnum {
  Pending = "pending",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
  Deleted = "deleted",
}

export enum TaskTypeEnum {
  PtmRawSeq = "ptm_raw_seq",
  PtmNewSeq = "ptm_new_seq",
  SurfaceRawSeq = "surface_raw_seq",
  SurfaceNewSeq = "surface_new_seq",
  SequenceNewMutate = "sequence_new_mutate",
}

export enum SeqAlignTypeEnum {
  ByPos = "by_pos",
  AlignToNewSequence = "align_to_new_sequence",
}

export enum MutationTypeEnum {
  SeqAll = "seq_all",
  SeqH = "seq_H",
  SeqL = "seq_L",
}
