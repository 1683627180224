import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { JobTypeEnum } from "@services/job/enum/job.enum";
import {
  AntibodyNumberingModel,
  CifIdToObjectModel,
  TreeNodeModel,
  FileStatusModel,
  FileInfoModel,
  FileSelectModel,
  FileIdRefArrModel,
} from "@services/editor/model/editor.model";
import { aggregateData } from "@pages/protein-editor/utils/data-process.util";
import { getFileJobListApi } from "@services/editor/editor.api";
import { RootState } from "../store";

export interface EditorState {
  value: number;
  incrementAmount: number;
  entities: any[];
  loading: boolean;

  // Record whether antigen and antibody creation is complete
  prepareRecords: Record<string, Record<string, boolean>>;
  antibodyMap: Record<string, AntibodyNumberingModel>;
  currentSelectCifId: string;
  cifIdToRefCompMap: Record<string, Record<string, string>>;
  surfaceRef: Record<string, string[]>;

  jobPanelVisible: boolean;
  selectJobType: JobTypeEnum | null;
  fileTreeList: TreeNodeModel[];
  fileTreeLoading: boolean;

  // higlight Tree
  jobResultVisible: boolean;
  textViewerVisible: boolean;
  humanizationDetailVisible: boolean;
  humannessDetailVisible: boolean;
  antibodyOptimizationVisible: boolean;
  mhciiDetailVisible: boolean;
  createDatasetVisible: boolean;
  datasetResultVisible: boolean;
  tempCifFileSet: Record<string, boolean>;
  cifFileSet: Record<string, boolean>;
  cifFileIds: string[];

  expandedKeys: string[];

  filesMap: Record<string, FileInfoModel>;

  fileDownloading: boolean;
  fileDownloadStatus: FileStatusModel | null;
  scrollToFeedback: boolean;
  jobFileCheckpoint: string;
  jobFileInitLoaded: boolean;

  fileIdMapStructureRef: Record<string, string>;

  fileIdMapChildrenRefArr: Record<string, string[]>;

  structureFiles: FileSelectModel[];

  antibodyOptimizationResultVisible: boolean;
  imageViewerVisible: boolean;
}

const initialState: EditorState = {
  value: 0,
  incrementAmount: 1,
  entities: [],
  loading: false,

  prepareRecords: {},
  // Automated creation of parameters
  antibodyMap: {},
  currentSelectCifId: "",
  cifIdToRefCompMap: {},
  surfaceRef: {},

  jobPanelVisible: false,
  selectJobType: null,
  fileTreeList: [],
  fileTreeLoading: false,
  mhciiDetailVisible: false,

  jobResultVisible: false,
  textViewerVisible: false,
  humanizationDetailVisible: false,
  humannessDetailVisible: false,
  antibodyOptimizationVisible: false,
  createDatasetVisible: false,
  datasetResultVisible: false,
  tempCifFileSet: {},
  cifFileSet: {},
  cifFileIds: [],
  filesMap: {},

  expandedKeys: [],
  fileDownloading: false,

  fileDownloadStatus: null,

  scrollToFeedback: false,
  jobFileCheckpoint: "",

  jobFileInitLoaded: false,
  fileIdMapStructureRef: {},
  fileIdMapChildrenRefArr: {},
  structureFiles: [],
  antibodyOptimizationResultVisible: false,
  imageViewerVisible: false,
};

export const getFileTreeByProject = createAsyncThunk(
  "editor/fetchFileTree",
  async (pid: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // const { list: fileList } = await getFileByProjectApi({
    //   page: 1,
    //   pageSize: 10000,
    //   pid,
    // });

    // const { list: jobList } = await getJobByProjectApi({
    //   page: 1,
    //   pageSize: 10000,
    //   pid,
    // });

    const data = await getFileJobListApi(pid);

    return aggregateData(data, state.editor.fileTreeList);
  }
);

export const editorSlice = createSlice({
  name: "editor",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += state.incrementAmount;
    },
    decrement: (state) => {
      state.value -= state.incrementAmount;
    },
    changeIncrementAmount: (state, action: PayloadAction<number>) => {
      state.incrementAmount = action.payload;
    },

    setCurrentSelectCifId(state, action: PayloadAction<string>) {
      state.currentSelectCifId = action.payload;
    },

    addToPepareRecords(
      state,
      action: PayloadAction<{ cifId: string; label: string }>
    ) {
      state.prepareRecords = {
        ...state.prepareRecords,
        [action.payload.cifId]: {
          ...state.prepareRecords[action.payload.cifId],
          [action.payload.label]: true,
        },
      };
    },
    removeFromPrepareRecords(state, action: PayloadAction<string>) {
      delete state.prepareRecords[action.payload];
    },
    clearPrepareRecords(state) {
      state.prepareRecords = {};
    },
    addToAntibodyMap(state, action: PayloadAction<AntibodyNumberingModel>) {
      state.antibodyMap = {
        ...state.antibodyMap,
        [action.payload.pdb_id.id]: action.payload,
      };
    },
    removeFromAntibodyMap(state, action: PayloadAction<string>) {
      delete state.antibodyMap[action.payload];
    },
    clearAllAntibodyMap(state) {
      state.antibodyMap = {};
    },
    addSurfaceRef(
      state,
      action: PayloadAction<{ cifId: string; ref: string }>
    ) {
      if (state.surfaceRef[action.payload.cifId]) {
        state.surfaceRef = {
          ...state.surfaceRef,
          [action.payload.cifId]: [
            ...state.surfaceRef[action.payload.cifId],
            action.payload.ref,
          ],
        };
      } else {
        state.surfaceRef = {
          ...state.surfaceRef,
          [action.payload.cifId]: [action.payload.ref],
        };
      }
    },
    removeSurfaceRef(state, action: PayloadAction<string>) {
      delete state.surfaceRef[action.payload];
    },
    clearSurfaceRef(state) {
      state.surfaceRef = {};
    },
    setCifIdToRefCompMap(
      state,
      action: PayloadAction<{ cifId: string; label: string; ref: string }>
    ) {
      state.cifIdToRefCompMap = {
        ...state.cifIdToRefCompMap,
        [action.payload.cifId]: {
          ...state.cifIdToRefCompMap[action.payload.cifId],
          [action.payload.label]: action.payload.ref,
        },
      };
    },
    removeCifIdToRefCompMap(state, action: PayloadAction<string>) {
      delete state.cifIdToRefCompMap[action.payload];
    },
    clearCifIdToRefComMap(state) {
      state.cifIdToRefCompMap = {};
    },
    clearCifIdToRefCompMap(state) {
      state.cifIdToRefCompMap = {};
    },
    addFileIdMapStructureRef(state, action: PayloadAction<CifIdToObjectModel>) {
      state.fileIdMapStructureRef = {
        ...state.fileIdMapStructureRef,
        [action.payload.cifId]: action.payload.ref,
      };
    },
    removeFileIdMapStructureRef(state, action: PayloadAction<string>) {
      delete state.fileIdMapStructureRef[action.payload];
    },
    clearFileIdMapStructureRef(state) {
      state.fileIdMapStructureRef = {};
    },

    addFileIdMapChildrenRefArr(
      state,
      action: PayloadAction<FileIdRefArrModel>
    ) {
      state.fileIdMapChildrenRefArr = {
        ...state.fileIdMapChildrenRefArr,
        [action.payload.cifId]: action.payload.arr,
      };
    },
    removeFileIdMapChildrenRefArr(state, action: PayloadAction<string>) {
      delete state.fileIdMapChildrenRefArr[action.payload];
    },
    clearFileIdMapChildrenRefArr(state) {
      state.fileIdMapChildrenRefArr = {};
    },

    setJobPanelVisible(state, action: PayloadAction<boolean>) {
      state.jobPanelVisible = action.payload;
    },
    setSelectJobType(state, action: PayloadAction<JobTypeEnum | null>) {
      state.selectJobType = action.payload;
    },
    addTempCifFile(state, action: PayloadAction<string>) {
      state.tempCifFileSet[action.payload] = true;
    },
    addCifFile(state, action: PayloadAction<string>) {
      state.cifFileSet = {
        ...state.tempCifFileSet,
        [action.payload]: true,
      };

      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.antibodyOptimizationResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
    },
    removeCifFile(state, action: PayloadAction<string>) {
      delete state.cifFileSet[action.payload];
      delete state.tempCifFileSet[action.payload];
    },
    addJob(state, action: PayloadAction<string>) {
      state.jobResultVisible = true;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.antibodyOptimizationResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
      state.cifFileSet = {
        [action.payload]: true,
      };
    },
    addAntibodyOptimizationJob(state, action: PayloadAction<string>) {
      state.antibodyOptimizationVisible = true;
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
      // state.antibodyOptimizationResultVisible = false;
      state.cifFileSet = {
        [action.payload]: true,
      };
    },
    removeAntibodyOptimizationJob(state) {
      state.antibodyOptimizationVisible = false;
      // state.cifFileSet = { ...state.tempCifFileSet };
    },
    addMhciiJob(state) {
      state.antibodyOptimizationVisible = false;
      state.jobResultVisible = true;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = true;
    },
    removeMhciiJob(state) {
      state.mhciiDetailVisible = false;
    },
    removeJob(state) {
      state.jobResultVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },
    addTextViewerFile(state, action: PayloadAction<string>) {
      state.jobResultVisible = false;
      state.textViewerVisible = true;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.antibodyOptimizationResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
      state.cifFileSet = {
        [action.payload]: true,
      };
    },
    removeTextViewerFile(state) {
      state.textViewerVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    addImageViewerFile(state, action: PayloadAction<string>) {
      state.imageViewerVisible = true;
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.antibodyOptimizationResultVisible = false;
      state.mhciiDetailVisible = false;
      state.cifFileSet = {
        [action.payload]: true,
      };
    },
    removeImageViewerFile(state) {
      state.imageViewerVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    addHumanizationDetailCsvFile(state, action: PayloadAction<string>) {
      state.humanizationDetailVisible = true;
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.antibodyOptimizationResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
      state.cifFileSet = {
        [action.payload]: true,
      };
    },

    viewHumanizationDetail(state) {
      state.humanizationDetailVisible = true;
    },

    closeHumanizationDetail(state) {
      state.humanizationDetailVisible = false;
    },

    removeHumanizationDetailCsvFile(state) {
      state.humanizationDetailVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    addHumannessDetailCsvFile(state, action: PayloadAction<string>) {
      state.humannessDetailVisible = true;
      state.humanizationDetailVisible = false;
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.antibodyOptimizationResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
      state.cifFileSet = {
        [action.payload]: true,
      };
    },

    viewHumannessDetail(state) {
      state.humannessDetailVisible = true;
    },

    closeHumannessDetail(state) {
      state.humannessDetailVisible = false;
    },

    removeHumannessDetailCsvFile(state) {
      state.humannessDetailVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    viewDatasetPanel(state, action: PayloadAction<string>) {
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = true;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;
      if (action.payload) {
        state.cifFileSet = {
          [action.payload]: true,
        };
      } else {
        state.cifFileSet = {};
      }
    },
    closeDatasetPanel(state) {
      state.createDatasetVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    viewDatasetResultPanel(state, action: PayloadAction<string>) {
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.humanizationDetailVisible = false;
      state.humannessDetailVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = true;
      state.antibodyOptimizationResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;

      state.cifFileSet = {
        [action.payload]: true,
      };
    },

    viewAntibodyOptimizationResultPanel(state, action: PayloadAction<string>) {
      state.antibodyOptimizationResultVisible = true;
      state.humannessDetailVisible = false;
      state.humanizationDetailVisible = false;
      state.jobResultVisible = false;
      state.textViewerVisible = false;
      state.antibodyOptimizationVisible = false;
      state.createDatasetVisible = false;
      state.datasetResultVisible = false;
      state.imageViewerVisible = false;
      state.mhciiDetailVisible = false;

      state.cifFileSet = {
        [action.payload]: true,
      };
    },

    closeAntibodyOptimizationResultPanel(state) {
      state.antibodyOptimizationResultVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    closeDatasetResultPanel(state) {
      state.datasetResultVisible = false;
      state.cifFileSet = { ...state.tempCifFileSet };
    },

    addCifFileId(state, action: PayloadAction<string>) {
      state.cifFileIds.push(action.payload);
    },

    removeCifFileId(state, action: PayloadAction<number>) {
      state.cifFileIds = state.cifFileIds.filter((id, idx) => {
        return action.payload !== idx;
      });
    },

    resetFileSelect(state) {
      state.cifFileIds = [];
      state.cifFileSet = {};
      state.tempCifFileSet = {};
    },

    setExpandedKeys(state, action: PayloadAction<string[]>) {
      state.expandedKeys = action.payload;
    },

    addExpandedKey(state, action: PayloadAction<string>) {
      state.expandedKeys = [...state.expandedKeys, action.payload];
    },

    removeExpandedKeys(state) {
      state.expandedKeys = [];
    },

    toggleFileDownloadState: (state, action: PayloadAction<boolean>) => {
      state.fileDownloading = action.payload;
    },

    setFileDownloadState: (
      state,
      action: PayloadAction<FileStatusModel | null>
    ) => {
      state.fileDownloadStatus = action.payload;
    },

    toggleScrollToFeedback: (state, action: PayloadAction<boolean>) => {
      state.scrollToFeedback = action.payload;
    },

    setFileTreeList: (state, action: PayloadAction<TreeNodeModel[]>) => {
      state.fileTreeList = action.payload;
    },

    setFilesMap: (
      state,
      action: PayloadAction<Record<string, FileInfoModel>>
    ) => {
      state.filesMap = {
        ...state.filesMap,
        ...action.payload,
      };
    },

    setJobFileCheckpoint: (state, action: PayloadAction<string>) => {
      state.jobFileCheckpoint = action.payload;
    },

    addStructureFiles: (state, action: PayloadAction<FileSelectModel>) => {
      state.structureFiles = [...state.structureFiles, action.payload];
    },
    resetStructureFiles: (state) => {
      state.structureFiles = [];
    },
    removeStructureFile: (state, action: PayloadAction<number>) => {
      const structureFiles = state.structureFiles;
      const list = structureFiles.filter((item, idx) => {
        return idx !== action.payload;
      });
      state.structureFiles = list;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFileTreeByProject.pending, (state) => {
      state.fileTreeLoading = true;
    });

    builder.addCase(getFileTreeByProject.fulfilled, (state, { payload }) => {
      state.fileTreeLoading = false;
      state.fileTreeList = payload.treeList;
      state.filesMap = {
        ...state.filesMap,
        ...payload.filesMap,
      };
      state.jobFileCheckpoint = payload.checkpoint;

      if (!state.jobFileInitLoaded) {
        state.jobFileInitLoaded = true;
      }
    });

    builder.addCase(getFileTreeByProject.rejected, (state) => {
      state.fileTreeLoading = false;
    });
  },
});

export const {
  increment,
  decrement,
  changeIncrementAmount,
  setJobPanelVisible,
  setSelectJobType,
  addTempCifFile,
  addCifFile,
  removeCifFile,
  addJob,
  removeJob,
  addTextViewerFile,
  removeTextViewerFile,
  addCifFileId,
  removeCifFileId,
  resetFileSelect,
  addHumanizationDetailCsvFile,
  removeHumanizationDetailCsvFile,
  viewHumanizationDetail,
  closeHumanizationDetail,
  viewHumannessDetail,
  closeHumannessDetail,
  removeHumannessDetailCsvFile,
  addHumannessDetailCsvFile,
  addToPepareRecords,
  removeFromPrepareRecords,
  addToAntibodyMap,
  removeFromAntibodyMap,
  clearAllAntibodyMap,
  setCurrentSelectCifId,
  setCifIdToRefCompMap,
  removeCifIdToRefCompMap,
  clearCifIdToRefComMap,
  clearPrepareRecords,
  removeSurfaceRef,
  clearSurfaceRef,
  addSurfaceRef,
  setExpandedKeys,
  removeExpandedKeys,
  addExpandedKey,
  toggleFileDownloadState,
  setFileDownloadState,
  toggleScrollToFeedback,
  addAntibodyOptimizationJob,
  removeAntibodyOptimizationJob,
  setFileTreeList,
  setFilesMap,
  addFileIdMapStructureRef,
  removeFileIdMapStructureRef,
  clearFileIdMapStructureRef,
  viewDatasetPanel,
  closeDatasetPanel,
  viewDatasetResultPanel,
  closeDatasetResultPanel,
  addStructureFiles,
  resetStructureFiles,
  removeStructureFile,
  viewAntibodyOptimizationResultPanel,
  closeAntibodyOptimizationResultPanel,
  addImageViewerFile,
  removeImageViewerFile,
  addFileIdMapChildrenRefArr,
  removeFileIdMapChildrenRefArr,
  clearFileIdMapChildrenRefArr,
  addMhciiJob,
  removeMhciiJob,
} = editorSlice.actions;

export default editorSlice.reducer;
