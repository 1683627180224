import { JobTypeEnum, JobStatusEnum, JobGroupEnum } from "../enum/job.enum";
import { LabelValueModel } from "@services/common/model/global.model";

export const JOB_TYPE_LABEL = {
  [JobTypeEnum.GenStructPred]: "Protein Structure Prediction",
  [JobTypeEnum.AbStructPred]: "Antibody Structure Prediction",
  [JobTypeEnum.DevPred]: "Developability Prediction",
  [JobTypeEnum.AbAgDocking]: "Antibody-Antigen Docking",
  [JobTypeEnum.AbNumbering]: "Interface Visualization",
  [JobTypeEnum.AffinityOptim]: "Affinity Optimization",
  [JobTypeEnum.AffinityPred]: "Affinity Prediction",
  [JobTypeEnum.DevOptim]: "Developability Optimization",
  [JobTypeEnum.DnAbDesign]: "Targeted CDR Library Design",
  [JobTypeEnum.HumanPred]: "Humanness Prediction",
  [JobTypeEnum.Humanization]: "Humanization",
  [JobTypeEnum.AbLightChainRetrieval]: "Common Light Chain Retrieval",
  [JobTypeEnum.ProteinMpnn]: "Protein Sequence Design",
  [JobTypeEnum.OptimizationAntibody]: "Antibody Optimization",
  [JobTypeEnum.ProteinStructureAlign]: "Structure Alignment",
  [JobTypeEnum.MHC2BindPredict]: "MHCII binding prediction",
  [JobTypeEnum.EpitopePredict]: "Epitope prediction",
};

export const JOB_TYPE_LIST: LabelValueModel[] = [
  {
    id: 0,
    label: JOB_TYPE_LABEL[JobTypeEnum.GenStructPred],
    value: JobTypeEnum.GenStructPred,
  },
  {
    id: 1,
    label: JOB_TYPE_LABEL[JobTypeEnum.AbStructPred],
    value: JobTypeEnum.AbStructPred,
  },
  {
    id: 2,
    label: JOB_TYPE_LABEL[JobTypeEnum.DevPred],
    value: JobTypeEnum.DevPred,
  },
  {
    id: 3,
    label: JOB_TYPE_LABEL[JobTypeEnum.AbAgDocking],
    value: JobTypeEnum.AbAgDocking,
  },
];

export const JOB_STATUS_LABEL = {
  [JobStatusEnum.Finished]: "Success",
  [JobStatusEnum.Failed]: "Failed",
  [JobStatusEnum.Executing]: "Running",
  [JobStatusEnum.Pending]: "Pending",
};

export const JOB_STATUS_LIST: LabelValueModel[] = [
  {
    id: 0,
    label: JOB_STATUS_LABEL[JobStatusEnum.Finished],
    value: JobStatusEnum.Finished,
  },
  {
    id: 1,
    label: JOB_STATUS_LABEL[JobStatusEnum.Failed],
    value: JobStatusEnum.Failed,
  },
  {
    id: 2,
    label: JOB_STATUS_LABEL[JobStatusEnum.Executing],
    value: JobStatusEnum.Executing,
  },
  {
    id: 3,
    label: JOB_STATUS_LABEL[JobStatusEnum.Pending],
    value: JobStatusEnum.Pending,
  },
];

export const JOB_STATUS_COLOR = {
  [JobStatusEnum.Finished]: "success",
  [JobStatusEnum.Failed]: "danger",
  [JobStatusEnum.Executing]: "warning",
  [JobStatusEnum.Pending]: "pending",
};

export const JOB_GROUP_LABEL = {
  [JobGroupEnum.Characterization]: "Characterization",
  [JobGroupEnum.Design]: "Design",
  [JobGroupEnum.Optimization]: "Optimization",
  [JobGroupEnum.Utils]: "Utils",
  [JobGroupEnum.StructModeling]: "Structure Modeling",
};
